.spinner-container {
  text-align: center;
  height: 100vh;

  auro-loader {
    margin-top: 25%;
  }
}

.custom-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 9;
  font-family: Arial, sans-serif;
  background-color: #f5f6f9;
}

.custom-spinner {
  border: 12px solid rgba(0, 0, 0, 0.1);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border-left-color: #0074c8;
  animation: spin 2s linear infinite;
}
