.form-check-input {
    position: relative;
    margin-top: 1rem;
    margin-left: 1.25rem;
    margin-right: 1rem;
  }

  .m-height {
    height: 380px;
    position: relative;
  }

  .h-text {
    padding-top: 1.7rem;
  }

  .c-header {
    display: grid;
    grid-template-columns: auto 15%;
  }

  .card-head {
    font-weight: 450;
    padding: 0 2rem 1rem 0;
    font-size: 0.9rem;
    line-height: 143%;
    width: 88%;
    margin: 0 2.5rem;
    color: black;
  }

  .card-text {
    padding: 0 2rem 0 0;
    width: 88%;
    margin: 0 2.5rem;
    color: #626b79;
  }

  .card-body {
    font-weight: 450;
    font-size: 0.75rem;
    color: var(--auro-color-text-secondary-on-light);
    line-height: 133%;
  }

  .c-footer {
    height: 80px;
    position: relative;
    bottom: 0;
    display: block;
    color: var(--auro-color-text-primary-on-light);
    margin: 0.8rem;
    border-radius: 0.2rem;
    padding: 0.6rem;
    background-color: #cde6ff63;
    font-weight: 450;
    font-size: 0.85rem;
    line-height: 133%;
  }

  section.text-center {
    background: #f8f9fa!important;
    margin: 0;
  }