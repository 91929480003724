@import "~@aurodesignsystem/design-tokens/dist/tokens/SassCustomProperties";
@import "~@aurodesignsystem/design-tokens/dist/tokens/SCSSVariables";
@import "~@aurodesignsystem/webcorestylesheets/src/breakpoints";
@import "~@aurodesignsystem/webcorestylesheets/src/fonts";
@import "~@aurodesignsystem/webcorestylesheets/src/normalize";
@import "~@aurodesignsystem/webcorestylesheets/src/essentials";

// global tokens that can be dynamically updated in the code
:root {
  --tier-status-background-color: #03314F;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "AS Circular", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
